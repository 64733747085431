import React from "react"
import PropTypes from "prop-types"
import { Row, Col } from "react-bootstrap"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

const Brands = ({ extended }) => {
  const {
    aceImg,
    acornsImg,
    adidasImg,
    adtImg,
    airbnbImg,
    albertsonsImg,
    amazonImg,
    attImg,
    billabongImg,
    blueApronImg,
    chewyImg,
    coinbaseImg,
    costcoImg,
    dollarGeneralImg,
    doordashImg,
    eharmonyImg,
    evernoteImg,
    expediaImg,
    fedExImg,
    gameFlyImg,
    gameStopImg,
    gapImg,
    greenManGamingImg,
    helloFreshImg,
    hrBlockImg,
    huluImg,
    kindImg,
    lancomeImg,
    lowesImg,
    macysImg,
    microsoftImg,
    monsterImg,
    newEggImg,
    nikeImg,
    nordstromRackImg,
    overstockImg,
    peetsImg,
    petcoImg,
    quickBooksImg,
    qvcImg,
    ralphsImg,
    sonosImg,
    spectrumImg,
    stashImg,
    stockxImg,
    tidalImg,
    timeLifeImg,
    uniqloImg,
    upsImg,
    walgreensImg,
    walmartImg,
    wholeFoodImg,
    zGallerieImg,
    zipRecruiterImg,
    zyngaImg,
  } = useStaticQuery(graphql`{
  peetsImg: file(relativePath: {eq: "brands/partner_logos_rectangle_peets.png"}) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  attImg: file(relativePath: {eq: "brands/partner_logos_rectangle_att.png"}) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  coinbaseImg: file(
    relativePath: {eq: "brands/partner_logos_rectangle_coinbase.png"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  adtImg: file(relativePath: {eq: "brands/partner_logos_rectangle_adt.png"}) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  acornsImg: file(relativePath: {eq: "brands/partner_logos_rectangle_acorns.png"}) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  doordashImg: file(
    relativePath: {eq: "brands/partner_logos_rectangle_doordash.png"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  stockxImg: file(relativePath: {eq: "brands/partner_logos_rectangle_stockx.png"}) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  chewyImg: file(relativePath: {eq: "brands/partner_logos_rectangle_chewy.png"}) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  eharmonyImg: file(
    relativePath: {eq: "brands/partner_logos_rectangle_eharmony.png"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  billabongImg: file(
    relativePath: {eq: "brands/partner_logos_rectangle_billabong.png"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  evernoteImg: file(
    relativePath: {eq: "brands/partner_logos_rectangle_evernote.png"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  zyngaImg: file(relativePath: {eq: "brands/partner_logos_rectangle_zynga.png"}) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  spectrumImg: file(
    relativePath: {eq: "brands/partner_logos_rectangle_spectrum.png"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  gapImg: file(relativePath: {eq: "brands/partner_logos_rectangle_gap.png"}) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  quickBooksImg: file(
    relativePath: {eq: "brands/partner_logos_rectangle_intuitqb.png"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  wholeFoodImg: file(
    relativePath: {eq: "brands/partner_logos_rectangle_wholefoods.png"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  airbnbImg: file(relativePath: {eq: "brands/partner_logos_rectangleairbnb.png"}) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  albertsonsImg: file(
    relativePath: {eq: "brands/partner_logos_rectanglealbertson.png"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  amazonImg: file(relativePath: {eq: "brands/partner_logos_rectangleamazon.png"}) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  adidasImg: file(relativePath: {eq: "brands/partner_logos_rectangleAdidas.png"}) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  blueApronImg: file(
    relativePath: {eq: "brands/partner_logos_rectangleblue_apron.png"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  fedExImg: file(relativePath: {eq: "brands/partner_logos_rectanglefedex.png"}) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  stashImg: file(relativePath: {eq: "brands/partner_logos_rectanglestash.png"}) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  costcoImg: file(relativePath: {eq: "brands/partner_logos_rectanglecostco.png"}) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  aceImg: file(
    relativePath: {eq: "brands/partner_logos_rectangleAce_hardware.png"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  tidalImg: file(relativePath: {eq: "brands/partner_logos_rectangleTidal.png"}) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  petcoImg: file(relativePath: {eq: "brands/partner_logos_rectanglepetco.png"}) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  zipRecruiterImg: file(
    relativePath: {eq: "brands/partner_logos_rectangleziprecruiter.png"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  newEggImg: file(relativePath: {eq: "brands/partner_logos_rectanglenewegg.png"}) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  microsoftImg: file(
    relativePath: {eq: "brands/partner_logos_rectanglemicrosoft.png"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  uniqloImg: file(relativePath: {eq: "brands/partner_logos_rectangleuniqlo.png"}) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  gameStopImg: file(
    relativePath: {eq: "brands/partner_logos_rectanglegamestop.png"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  expediaImg: file(
    relativePath: {eq: "brands/partner_logos_rectangleexpedia.png"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  dollarGeneralImg: file(
    relativePath: {eq: "brands/partner_logos_rectangledollar_general.png"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  monsterImg: file(
    relativePath: {eq: "brands/partner_logos_rectanglemonster.png"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  nordstromRackImg: file(
    relativePath: {eq: "brands/partner_logos_rectanglenordstrom_rack.png"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  lowesImg: file(relativePath: {eq: "brands/partner_logos_rectangleLowes.png"}) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  upsImg: file(relativePath: {eq: "brands/partner_logos_rectangleUPS.png"}) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  qvcImg: file(relativePath: {eq: "brands/partner_logos_rectangleqvc.png"}) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  greenManGamingImg: file(
    relativePath: {eq: "brands/partner_logos_rectanglegreen_man_gaming.png"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  helloFreshImg: file(
    relativePath: {eq: "brands/partner_logos_rectanglehello_fresh.png"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  gameFlyImg: file(
    relativePath: {eq: "brands/partner_logos_rectanglegamefly.png"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  huluImg: file(relativePath: {eq: "brands/partner_logos_rectanglehulu.png"}) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  nikeImg: file(relativePath: {eq: "brands/partner_logos_rectanglenike.png"}) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  ralphsImg: file(relativePath: {eq: "brands/partner_logos_rectangleralphs.png"}) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  macysImg: file(relativePath: {eq: "brands/partner_logos_rectangleMacys.png"}) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  overstockImg: file(
    relativePath: {eq: "brands/partner_logos_rectangleoverstock.png"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  zGallerieImg: file(
    relativePath: {eq: "brands/partner_logos_rectangleZ_Gallerie.png"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  walgreensImg: file(
    relativePath: {eq: "brands/partner_logos_rectanglewalgreens.png"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  walmartImg: file(
    relativePath: {eq: "brands/partner_logos_rectanglewalmart.png"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  hrBlockImg: file(
    relativePath: {eq: "brands/partner_logos_rectanglehrblock.png"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  kindImg: file(relativePath: {eq: "brands/partner_logos_rectanglekind.png"}) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  lancomeImg: file(
    relativePath: {eq: "brands/partner_logos_rectanglelancome.png"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  sonosImg: file(relativePath: {eq: "brands/partner_logos_rectanglesonos.png"}) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  timeLifeImg: file(
    relativePath: {eq: "brands/partner_logos_rectangletimelife.png"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
}
`)

  return (
    <div className="brand-partnerships">
      <Row>
        <Col>
          <GatsbyImage
            image={peetsImg.childImageSharp.gatsbyImageData}
            imgStyle={{ objectFit: "contain" }}
            className="brands-img"
            alt="Peet's Coffee" />
        </Col>
        <Col>
          <GatsbyImage
            image={attImg.childImageSharp.gatsbyImageData}
            imgStyle={{ objectFit: "contain" }}
            className="brands-img"
            alt="AT&amp;T" />
        </Col>
        <Col>
          <GatsbyImage
            image={coinbaseImg.childImageSharp.gatsbyImageData}
            imgStyle={{ objectFit: "contain" }}
            className="brands-img"
            alt="Coinbase" />
        </Col>
        <div className="w-100 d-md-none"></div>
        <Col>
          <GatsbyImage
            image={adtImg.childImageSharp.gatsbyImageData}
            imgStyle={{ objectFit: "contain" }}
            className="brands-img"
            alt="ADT" />
        </Col>
        <div className="d-none d-md-block w-100"></div>
        <Col>
          <GatsbyImage
            image={acornsImg.childImageSharp.gatsbyImageData}
            imgStyle={{ objectFit: "contain" }}
            className="brands-img"
            alt="Acorns" />
        </Col>
        <Col>
          <GatsbyImage
            image={doordashImg.childImageSharp.gatsbyImageData}
            imgStyle={{ objectFit: "contain" }}
            className="brands-img"
            alt="Door Dash" />
        </Col>
        <div className="w-100 d-md-none"></div>
        <Col>
          <GatsbyImage
            image={stockxImg.childImageSharp.gatsbyImageData}
            imgStyle={{ objectFit: "contain" }}
            className="brands-img"
            alt="Stock X" />
        </Col>
        <Col>
          <GatsbyImage
            image={chewyImg.childImageSharp.gatsbyImageData}
            imgStyle={{ objectFit: "contain" }}
            className="brands-img"
            alt="Chewy" />
        </Col>
        <div className="d-none d-md-block w-100"></div>
        <Col>
          <GatsbyImage
            image={eharmonyImg.childImageSharp.gatsbyImageData}
            imgStyle={{ objectFit: "contain" }}
            className="brands-img"
            alt="eHarmony" />
        </Col>
        <div className="w-100 d-md-none"></div>
        <Col>
          <GatsbyImage
            image={billabongImg.childImageSharp.gatsbyImageData}
            imgStyle={{ objectFit: "contain" }}
            className="brands-img"
            alt="Billabong" />
        </Col>
        <Col>
          <GatsbyImage
            image={evernoteImg.childImageSharp.gatsbyImageData}
            imgStyle={{ objectFit: "contain" }}
            className="brands-img"
            alt="Evernote" />
        </Col>
        <Col>
          <GatsbyImage
            image={zyngaImg.childImageSharp.gatsbyImageData}
            imgStyle={{ objectFit: "contain" }}
            className="brands-img"
            alt="Zynga" />
        </Col>
        {extended && (
          <>
            <div className="w-100 d-md-none"></div>
            <div className="d-none d-md-block w-100"></div>
            <Col>
              <GatsbyImage
                image={spectrumImg.childImageSharp.gatsbyImageData}
                imgStyle={{ objectFit: "contain" }}
                className="brands-img"
                alt="Spectrum" />
            </Col>
            <Col>
              <GatsbyImage
                image={gapImg.childImageSharp.gatsbyImageData}
                imgStyle={{ objectFit: "contain" }}
                className="brands-img"
                alt="Gap" />
            </Col>
            <Col>
              <GatsbyImage
                image={quickBooksImg.childImageSharp.gatsbyImageData}
                imgStyle={{ objectFit: "contain" }}
                className="brands-img"
                alt="Quickbooks" />
            </Col>
            <div className="w-100 d-md-none"></div>
            <Col>
              <GatsbyImage
                image={wholeFoodImg.childImageSharp.gatsbyImageData}
                imgStyle={{ objectFit: "contain" }}
                className="brands-img"
                alt="Whole Foods" />
            </Col>
            <div className="d-none d-md-block w-100"></div>
            <Col>
              <GatsbyImage
                image={airbnbImg.childImageSharp.gatsbyImageData}
                imgStyle={{ objectFit: "contain" }}
                className="brands-img"
                alt="airbnb" />
            </Col>
            <Col>
              <GatsbyImage
                image={albertsonsImg.childImageSharp.gatsbyImageData}
                imgStyle={{ objectFit: "contain" }}
                className="brands-img"
                alt="Albertsons" />
            </Col>
            <div className="w-100 d-md-none"></div>
            <Col>
              <GatsbyImage
                image={amazonImg.childImageSharp.gatsbyImageData}
                imgStyle={{ objectFit: "contain" }}
                className="brands-img"
                alt="Amazon" />
            </Col>
            <Col>
              <GatsbyImage
                image={adidasImg.childImageSharp.gatsbyImageData}
                imgStyle={{ objectFit: "contain" }}
                className="brands-img"
                alt="Adidas" />
            </Col>
            <div className="d-none d-md-block w-100"></div>
            <Col>
              <GatsbyImage
                image={blueApronImg.childImageSharp.gatsbyImageData}
                imgStyle={{ objectFit: "contain" }}
                className="brands-img"
                alt="Blue Apron" />
            </Col>
            <div className="w-100 d-md-none"></div>
            <Col>
              <GatsbyImage
                image={fedExImg.childImageSharp.gatsbyImageData}
                imgStyle={{ objectFit: "contain" }}
                className="brands-img"
                alt="FedEx" />
            </Col>
            <Col>
              <GatsbyImage
                image={stashImg.childImageSharp.gatsbyImageData}
                imgStyle={{ objectFit: "contain" }}
                className="brands-img"
                alt="Stash" />
            </Col>
            <Col>
              <GatsbyImage
                image={costcoImg.childImageSharp.gatsbyImageData}
                imgStyle={{ objectFit: "contain" }}
                className="brands-img"
                alt="Costco" />
            </Col>
            <div className="w-100 d-md-none"></div>
            <div className="d-none d-md-block w-100"></div>
            <Col>
              <GatsbyImage
                image={aceImg.childImageSharp.gatsbyImageData}
                imgStyle={{ objectFit: "contain" }}
                className="brands-img"
                alt="Ace Hardware" />
            </Col>
            <Col>
              <GatsbyImage
                image={tidalImg.childImageSharp.gatsbyImageData}
                imgStyle={{ objectFit: "contain" }}
                className="brands-img"
                alt="Tidal" />
            </Col>
            <Col>
              <GatsbyImage
                image={petcoImg.childImageSharp.gatsbyImageData}
                imgStyle={{ objectFit: "contain" }}
                className="brands-img"
                alt="Petco" />
            </Col>
            <div className="w-100 d-md-none"></div>
            <Col>
              <GatsbyImage
                image={zipRecruiterImg.childImageSharp.gatsbyImageData}
                imgStyle={{ objectFit: "contain" }}
                className="brands-img"
                alt="Zip Recruiter" />
            </Col>
            <div className="d-none d-md-block w-100"></div>
            <Col>
              <GatsbyImage
                image={newEggImg.childImageSharp.gatsbyImageData}
                imgStyle={{ objectFit: "contain" }}
                className="brands-img"
                alt="New Egg" />
            </Col>
            <Col>
              <GatsbyImage
                image={microsoftImg.childImageSharp.gatsbyImageData}
                imgStyle={{ objectFit: "contain" }}
                className="brands-img"
                alt="Microsoft" />
            </Col>
            <div className="w-100 d-md-none"></div>
            <Col>
              <GatsbyImage
                image={uniqloImg.childImageSharp.gatsbyImageData}
                imgStyle={{ objectFit: "contain" }}
                className="brands-img"
                alt="UNIQLO" />
            </Col>
            <Col>
              <GatsbyImage
                image={gameStopImg.childImageSharp.gatsbyImageData}
                imgStyle={{ objectFit: "contain" }}
                className="brands-img"
                alt="Game Stop" />
            </Col>
            <div className="d-none d-md-block w-100"></div>
            <Col>
              <GatsbyImage
                image={expediaImg.childImageSharp.gatsbyImageData}
                imgStyle={{ objectFit: "contain" }}
                className="brands-img"
                alt="Expedia" />
            </Col>
            <div className="w-100 d-md-none"></div>
            <Col>
              <GatsbyImage
                image={dollarGeneralImg.childImageSharp.gatsbyImageData}
                imgStyle={{ objectFit: "contain" }}
                className="brands-img"
                alt="Dollar General" />
            </Col>
            <Col>
              <GatsbyImage
                image={monsterImg.childImageSharp.gatsbyImageData}
                imgStyle={{ objectFit: "contain" }}
                className="brands-img"
                alt="Monster" />
            </Col>
            <Col>
              <GatsbyImage
                image={nordstromRackImg.childImageSharp.gatsbyImageData}
                imgStyle={{ objectFit: "contain" }}
                className="brands-img"
                alt="Nordstrom Rack" />
            </Col>
            <div className="w-100 d-md-none"></div>
            <div className="d-none d-md-block w-100"></div>
            <Col>
              <GatsbyImage
                image={lowesImg.childImageSharp.gatsbyImageData}
                imgStyle={{ objectFit: "contain" }}
                className="brands-img"
                alt="Lowes" />
            </Col>
            <Col>
              <GatsbyImage
                image={upsImg.childImageSharp.gatsbyImageData}
                imgStyle={{ objectFit: "contain" }}
                className="brands-img"
                alt="UPS" />
            </Col>
            <Col>
              <GatsbyImage
                image={qvcImg.childImageSharp.gatsbyImageData}
                imgStyle={{ objectFit: "contain" }}
                className="brands-img"
                alt="QVC" />
            </Col>
            <div className="w-100 d-md-none"></div>
            <Col>
              <GatsbyImage
                image={greenManGamingImg.childImageSharp.gatsbyImageData}
                imgStyle={{ objectFit: "contain" }}
                className="brands-img"
                alt="Green Man Gaming" />
            </Col>
            <div className="d-none d-md-block w-100"></div>
            <Col>
              <GatsbyImage
                image={helloFreshImg.childImageSharp.gatsbyImageData}
                imgStyle={{ objectFit: "contain" }}
                className="brands-img"
                alt="Hello Fresh" />
            </Col>
            <Col>
              <GatsbyImage
                image={gameFlyImg.childImageSharp.gatsbyImageData}
                imgStyle={{ objectFit: "contain" }}
                className="brands-img"
                alt="Game Fly" />
            </Col>
            <div className="w-100 d-md-none"></div>
            <Col>
              <GatsbyImage
                image={huluImg.childImageSharp.gatsbyImageData}
                imgStyle={{ objectFit: "contain" }}
                className="brands-img"
                alt="Hulu" />
            </Col>
            <Col>
              <GatsbyImage
                image={nikeImg.childImageSharp.gatsbyImageData}
                imgStyle={{ objectFit: "contain" }}
                className="brands-img"
                alt="Nike" />
            </Col>
            <div className="d-none d-md-block w-100"></div>
            <Col>
              <GatsbyImage
                image={ralphsImg.childImageSharp.gatsbyImageData}
                imgStyle={{ objectFit: "contain" }}
                className="brands-img"
                alt="Ralphs" />
            </Col>
            <div className="w-100 d-md-none"></div>
            <Col>
              <GatsbyImage
                image={macysImg.childImageSharp.gatsbyImageData}
                imgStyle={{ objectFit: "contain" }}
                className="brands-img"
                alt="Macys" />
            </Col>
            <Col>
              <GatsbyImage
                image={overstockImg.childImageSharp.gatsbyImageData}
                imgStyle={{ objectFit: "contain" }}
                className="brands-img"
                alt="Overstock" />
            </Col>
            <Col>
              <GatsbyImage
                image={zGallerieImg.childImageSharp.gatsbyImageData}
                imgStyle={{ objectFit: "contain" }}
                className="brands-img"
                alt="Z Gallerie" />
            </Col>
            <div className="w-100 d-md-none"></div>
            <div className="d-none d-md-block w-100"></div>
            <Col>
              <GatsbyImage
                image={walgreensImg.childImageSharp.gatsbyImageData}
                imgStyle={{ objectFit: "contain" }}
                className="brands-img"
                alt="Walgreens" />
            </Col>
            <Col>
              <GatsbyImage
                image={walmartImg.childImageSharp.gatsbyImageData}
                imgStyle={{ objectFit: "contain" }}
                className="brands-img"
                alt="Walmart" />
            </Col>
            <Col>
              <GatsbyImage
                image={gameFlyImg.childImageSharp.gatsbyImageData}
                imgStyle={{ objectFit: "contain" }}
                className="brands-img"
                alt="Game Fly" />
            </Col>
            <div className="w-100 d-md-none"></div>
            <Col>
              <GatsbyImage
                image={hrBlockImg.childImageSharp.gatsbyImageData}
                imgStyle={{ objectFit: "contain" }}
                className="brands-img"
                alt="HR Block" />
            </Col>
            <div className="d-none d-md-block w-100"></div>
            <Col>
              <GatsbyImage
                image={kindImg.childImageSharp.gatsbyImageData}
                imgStyle={{ objectFit: "contain" }}
                className="brands-img"
                alt="Kind" />
            </Col>
            <Col>
              <GatsbyImage
                image={lancomeImg.childImageSharp.gatsbyImageData}
                imgStyle={{ objectFit: "contain" }}
                className="brands-img"
                alt="Lancome" />
            </Col>
            <div className="w-100 d-md-none"></div>
            <Col>
              <GatsbyImage
                image={sonosImg.childImageSharp.gatsbyImageData}
                imgStyle={{ objectFit: "contain" }}
                className="brands-img"
                alt="Sonos" />
            </Col>
            <Col>
              <GatsbyImage
                image={timeLifeImg.childImageSharp.gatsbyImageData}
                imgStyle={{ objectFit: "contain" }}
                className="brands-img"
                alt="Time Life" />
            </Col>
          </>
        )}
      </Row>
    </div>
  );
}

Brands.defaultProps = {
  extended: false,
}

Brands.propTypes = {
  extended: PropTypes.bool,
}

export default Brands
